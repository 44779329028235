import React from 'react'
import Header from '../components/header'
import About from '../components/about'
import Pilares from "../components/icons";
import '../styles/app.css'
import ImportExportSection from "../components/import-export-section";
import Agent from "../components/agent";
import IETypes from '../components/ietypes'
import Contact from '../components/contact'
import FooterMega from '../components/footer-mega'
import CookieConsent, { Cookies } from "react-cookie-consent";
function Home() {
  return (
    <div className="App">

    <div className='content'>
      <Header />
      <Pilares />
      <IETypes />
      <section id='quemsomos'>
      <About />
      </section>
      <ImportExportSection />
      <Agent />
      <section id='contact'>
      <Contact  />

      </section>
      <FooterMega />
      <CookieConsent
        location="bottom"
        buttonText="Ok"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#fff", color: 'black', fontFamily: 'montserrat' }}
        buttonStyle={{ color: "white", fontSize: "16px", background: '#fd0000', paddingLeft: '70px', paddingRight: '70px', paddingTop: '10px', paddingBottom: '10px', }}
        expires={150}
      >
        Utilizamos cookies essenciais e tecnologias semelhantes de acordo com a nossa Política de Privacidade e, ao continuar navegando, você concorda com estas condições.
        {" "}
      </CookieConsent>

    </div>

  </div>
  )
}

export default Home