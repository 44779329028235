import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import megaLogo from '../img/logo.png'
import '../styles/navbar-style.css'
import { NavbarData,RedirectData } from './NavbarData';


function Navbar() {
  const [active, setActive] = useState("nav__menu");
  const [icon, setIcon] = useState("nav__toggler");
  const navToggle = () => {
    if (active === "nav__menu") {
      setActive("nav__menu nav__active");
    } else setActive("nav__menu");

    // Icon Toggler
    if (icon === "nav__toggler") {
      setIcon("nav__toggler toggle");
    } else setIcon("nav__toggler");
  };
  return (
    <nav className="nav">
      <a className="nav__brand">
        <Link to='/'> <img
          className="icon-photo"
          src={megaLogo}
          alt={"ConstruInvest"}
          style={{
            resizeMode: "cover",
            height: 50,
            width: 141
          }} /> </Link>
        
          
   
          
      </a>
      <ul className={active}>
      {RedirectData.map ((item,index) =>{
        return <li key={index} className={item.li_cName}>
          <Link to={item.path}>
            <a className={item.cName}>{item.title}</a>
          </Link>
        </li>
       })}
       {NavbarData.map ((item,index) =>{
        return <li key={index} className={item.li_cName}>
          <Link to={item.path}>
            <HashLink smooth to={item.hashlink}>
            <a className={item.cName}>{item.title}</a>
            </HashLink>
          </Link>
        </li>
       })}
      
      </ul>
      <div onClick={navToggle} className={icon}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
    </nav>
  );
}

export default Navbar;