import '../styles/contact-style.css'
import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser';




export default function Contact() {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_050jxwv', 'template_hxo98jj', form.current, 'D1uFwziNkb4ZtN31c')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };



    return (
        <>
            <div id='contact-main'>
                <form id='contact-form' onSubmit={sendEmail} ref={form}>
                    <label id='label-contact'>Em breve a nossa equipe entrará em contato</label>

                    <input required className='contact-input' type='text' name='name' placeholder='O seu nome' />
                    <input required className='contact-input' type='text' name='empresa' placeholder='Nome da sua empresa' />
                    <input required className='contact-input' type='email' name='email' placeholder='Seu e-mail' />
                    <input required className='contact-input' type='text' name='number' placeholder='Seu número' />
                    <input required className='contact-input' type='text' name='pergunta_01' placeholder='Já importou alguma vez?' />
                    <input required className='contact-input' type='text' name='pergunta_02' placeholder='Tem interessem em importar?' />
                    <input className='contact-input' type='text' name='message' placeholder='Se sim, tem algum nicho especifico ou produto desejado?' />
                    <input className='contact-input' id='form-button' type='submit' name='button' />

                </form>
            </div>
        </>
    )
}