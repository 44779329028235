import React from 'react'
import Agent from '../components/agent'
import FooterMega from '../components/footer-mega'
import Image from '../img/ship.jpg'
import '../styles/empresa-page.css'
import IconMega from '../img/icone.png'

function Empresa() {
    return (
        < >
            <div className='main-container'>
                <div className='left'><div className='title-empresa'><img src={IconMega} /><h1>A nossa história...</h1></div>
                    <p>A <span className='mega-bold'><span className='mega-bold'>Mega Trading</span></span> é uma comercial importadora, constituída a fim de atender médias e grandes empresas que não possuem domínio ou buscam otimizar seus processos de importação e exportação.</p>
                    <p>Visando oferecer a melhor estrutura para as empresas, a <span className='mega-bold'>Mega Trading</span> flexibiliza o sistema de logística, gerenciando todo o processo de importação e/ou exportação até a  chegada em seu destino, assegurando a otimização e eficiência dos resultados.</p>
                    <p>A empresa se encontra sediada na cidade de Extrema (MG), possuindo também uma filial na cidade de Itajaí (SC). Gerenciada por profissionais altamente qualificados, com experiência há mais de 20 anos no mercado de comércio exterior; importar e exportar são apenas alguns serviços que a <span className='mega-bold'>Mega Trading</span> pode lhe oferecer. Pensando em aumentar as oportunidades para nossos clientes, estamos atentos ao surgimento de novos mercados e fornecedores, buscando sempre novos meios para a realização de bons negócios.</p>
                    <p>
                        Nossos serviços envolvem as cotações e contratações de fretes internacionais seguros,  além de assessoria tributária na identificação da melhor logística para cada operação.</p>
                    <p>
                        As localizações estratégicas de nossas unidades abrangem as regiões Sul, Sudeste e Centro-oeste, com regimes especiais para importação em ambos os estados citados.

                    </p>

                </div>
                <div className='right'>
                    <img src={Image}/>
                </div>

            </div>
            <FooterMega/>
        </>
    )
}

export default Empresa