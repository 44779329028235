import React from 'react'
import '../styles/agent-style.css'
import BrenoDiasImage from '../img/brenodias.png'
import CeoAvatar from '../img/ceoavatar.png'

export default function Agent() {
    return (
        <>
            <div id='main-container'>
                <div id='avatar'>
                    <img id='avatar-img' src={BrenoDiasImage} />
                </div>
                <div id='text-container'>

                    <div id='title-div'>
                        <img id='ceoavatar' src={CeoAvatar} />
                        <div id='text-div'>
                            <h1 id='title'> Breno Luiz Dias</h1>
                            <h2 id='ceo'>CEO</h2>
                        </div>
                    </div>


                    <p className='paragraph-avatar'>Autêntico no mundo dos negócios de importação e exportação com uma expertise de mais de 20 anos nesse meio, destaca-se por sua habilidade em resolver problemas complexos e desenvolver estrutura tributária e financeira em ênfase nos benefícios fiscais e segurança legal da importação.</p>
                    <p className='paragraph-avatar'>Formado em comércio exterior,
                        foi responsável por desenvolver a trade do
                        banco BMG e logo após responsável
                        pela estrutura da terceira maior trade do Brasil.</p>
                    <p className='paragraph-avatar'>Atualmente é o cabeça e gerencia todas as etapas
                        do processo de importação/exportação <span id='mega'>Mega Trading</span>,
                        desde a origem de sua mercadoria até a entrega no
                        destino final, sempre em busca da
                        otimização de resultados</p>

                </div>


            </div>
        </>
    )
}