import React from "react";
import '../styles/about-style.css'
import AboutImage from '../img/import-export.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from "react-router-dom";

export default function About() {
    AOS.init();
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // for smoothly scrolling
        });
      };
    return (
            <div className='about-container'>
                <div className='image-about' data-aos="fade-right">
                    <img className='about floating' src={AboutImage}/>

                </div>
                <div className='text-container' data-aos='fade-in' data-aos-delay='300'>
                    <h1 id='title-about-id'> A nossa Empresa </h1>
                    <div className="line"></div>
                    <p className='paragraph-about'>A Mega Trading é uma comercial importadora, constituída a fim de atender médias e grandes empresas que não possuem domínio ou buscam otimizar seus processos de importação e exportação.</p>
                    <p className='paragraph-about'>Visando oferecer a melhor estrutura para as empresas, a Mega Trading flexibiliza o sistema de logística, gerenciando todo o processo de importação e/ou exportação até a  chegada em seu destino, assegurando a otimização e eficiência dos resultados.</p>
                    <div className='btn-group'>
                 
                       
                        <Link 
                        className="btn-btn" onClick={scrollToTop} to='/empresa'> A nossa história</Link>

                    
                </div>
                </div>
               
            </div>

       )
}