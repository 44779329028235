import React from "react";
import '../styles/icons-styles.css';
import CompetenciaIcon from '../img/competencia.png';
import FlexibilidadeIcon from '../img/flexibilidade.png';
import AgilidadeIcon from '../img/agilidade.png';
import ArrowRigth from '../img/arrow.png';
import ArrowDown from '../img/arrow-d.png'
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Pilares() {
    AOS.init();
    const imageUrl = window.innerWidth >= 550 ? ArrowRigth : ArrowDown;

    return (
            <div className='icon-main-container'>
                <h7 classname='icon-title'>Os nossos pilares</h7>
                <div className='icon-container' >
                    <div className='box-icon' data-aos="fade-right">
                        <img src={CompetenciaIcon} alt='competencia' />
                        <h1>Competência</h1>
                    </div>
                    <img className='arrow' src={imageUrl} data-aos="fade-right" data-aos-delay='300' />
                    <div className='box-icon' data-aos="fade-right" data-aos-delay='600'>
                        <img src={FlexibilidadeIcon} alt='competencia' />
                        <h1>Flexibilidade</h1>
                    </div>
                    <img className='arrow' src={imageUrl} data-aos="fade-right" data-aos-delay='800' />

                    <div className='box-icon' data-aos="fade-right" data-aos-delay='1000'>
                        <img src={AgilidadeIcon} alt='competencia' />
                        <h1>Agilidade</h1>
                    </div>
                </div>
            </div>
    )
}