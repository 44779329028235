import '../styles/ietypes-style.css'
import React from 'react';
import Modalidade1 from '../img/demanda.png'
import Modalidade2 from '../img/encomendas.png'
import Modalidade3 from '../img/equipe.png'
import 'aos/dist/aos.css';
import Aos from 'aos';

export default function IETypes() {
    Aos.init()
    return (
        <>
            <div id='iet-main' className='floating' data-aos='fade-up' data-aos-delay='400' data-aos-duration='600'>
                <div id='iet-title-container'> <h1 id='iet-title'>MODELOS DE IMPORTAÇÃO</h1></div>
                <div id='iet-container'>

                    <div className='iet-box'>
                        <div className='iet-box-title'>
                            <img className='iet-box-icon' src={Modalidade1} />
                            <h1 className='iet-box-h1'>Por conta e ordem de terceiros</h1>
                        </div>
                        <div className='iet-box-paragraph'>
                            <p className='iet-box-p'>Modalidade de operação em que o cliente contrata a <span className='mega-red'>Mega Trading</span> como prestadora de serviços para realizar a gestão do processo, desde origem até o desembaraço e entrega das mercadorias.</p>
                        </div>
                    </div>
                    <div className='iet-box'>
                    <div className='iet-box-title'>
                            <img className='iet-box-icon' src={Modalidade2} />
                            <h1 className='iet-box-h1'>Por encomenda</h1>
                        </div>
                        <div className='iet-box-paragraph'>
                            <p className='iet-box-p'>Modalidade de operação que evita necessidade de abertura de filial do cliente em estados com regimes especiais, por se tratar de operação de venda quando da transferência de titularidade das mercadorias.</p>
                        </div>
                    </div>
                    <div className='iet-box'>
                    <div className='iet-box-title'>
                            <img className='iet-box-icon' src={Modalidade3} />
                            <h1 className='iet-box-h1'>Importação para distribuição</h1>
                        </div>
                        <div className='iet-box-paragraph'>
                            <p className='iet-box-p'>Modalidade de operação praticada para produtos desenvolvidos pela <span className='mega-red'>Mega Trading</span> junto a fornecedores internacionais para serem ofertados no mercado interno após nacionalização.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}