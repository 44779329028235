import Navbar from "./components/navbar";
import './styles/app.css'
import Home from "./pages/Home";
import Empresa from "./pages/Empresa";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
    <Router>
      <Navbar />
      <Routes>
        <Route element={<Home/>} path='/' exact/>
        <Route element={<Empresa/>} path='/empresa'/>
      </Routes>
   
    </Router>
    
    </>

  );
}

export default App;
