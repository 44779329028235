import '../styles/footer-style.css'
import React from 'react'
import MegaLogo from '../img/logo.png'
import { TbBrandLinkedin, TbBrandFacebook, TbBrandInstagram } from "react-icons/tb"


export default function FooterMega() {
    return (<>
        <div id='id-footer'>
            <div className='footer-box'>
                <img id='footer-image' src={MegaLogo} />
                <div className='footer-links'>
                    <a className='footer-refs'>Página Inicial</a>
                    <a className='footer-refs'>A Empresa</a>
                    <a className='footer-refs'>Dúvidas Frequentes</a>
                    <a className='footer-refs'>Politíca de Privacidade</a>
                    <a className='footer-refs'>Fale conosco</a>
                </div>
            </div>
            <div className='footer-box' id='box-p'>
                <div className='footer-paragraph'>
                    <p className='footer-p'>A empresa se encontra sediada na cidade de Extrema (MG), possuindo também uma filial na cidade de Itajaí (SC). Gerenciada por profissionais altamente qualificados, com experiência há mais de 20 anos no mercado de comércio exterior; importar e exportar são apenas alguns serviços que a Mega Trading pode lhe oferecer. Pensando em aumentar as oportunidades para nossos clientes, estamos atentos ao surgimento de novos mercados e fornecedores, buscando sempre novos meios para a realização de bons negócios.

                    </p>
                    <p className='footer-p'> Nossos serviços envolvem as cotações e contratações de fretes internacionais seguros,  além de assessoria tributária na identificação da melhor logística para cada operação. </p>


                </div>
            </div>

            <div className='footer-box social-icons'>
                <div className='footer-paragraph social-icons'>
                    <p className='footer-p social-icons' id="copy-p">
                        <TbBrandLinkedin
                            size={32} // set custom `width` and `height`
                            color="red" // set `stroke` color
                            strokeLinejoin="miter"
                            className='icon-footer'
                        />
                        <TbBrandFacebook
                            size={32} // set custom `width` and `height`
                            color="red" // set `stroke` color
                            strokeLinejoin="miter" className='icon-footer'
                        />
                        <TbBrandInstagram
                            size={32} // set custom `width` and `height`
                            color="red" // set `stroke` color
                            strokeLinejoin="miter" className='icon-footer'
                        />

                    </p>
                </div>
            </div>
            <div className='footer-box' id='addr'>
                <div className='footer-paragraph'>
                    <p className='footer-p p-contact' id="copy-p">
                      <strong>Matriz</strong><br/>Contato: +55 35 31800999<br/>Rua Nilo Chede, 67 - Galpão 01<br/>Extrema-MG/BR
                    </p>
                    
                </div>
                <div className='footer-paragraph'>
                    <p className='footer-p p-contact' id="copy-p">
                      <strong>Filial</strong><br/>Contato: +55 47 35140588<br/>Rua Manoel Vieira Garção, 77/Sala 804 - Centro<br/>Itajaí-SC/BR
                    </p>
                    
                </div>
                
            </div>
            <div className='footer-box'><div className='footer-paragraph'>
                <p className='footer-p' id="copy-p">
                    © 2022 - Mega Trading
                </p>
                <p className='footer-p2' id="copy-p">
                    Desenvolvido por Igor Gomes
                </p>
            </div>
            </div>
        </div>
    </>)
}