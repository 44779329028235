import React from "react";
import '../styles/import-export-style.css'
import VantagensImage from '../img/vantagens.png'
import 'aos/dist/aos.css';
import Aos from 'aos';

export default function ImportExportSection() {
    Aos.init()
    return (
        <>
            <div className='iexp-container' data-aos='fade-in' data-aos-delay='600'>
                <div className='box-container'>
                    <div className='numbers'>
                        <h1 id='number-one'>1</h1>
                        <h1 id='number-two'>2</h1>
                        <h1 id='number-three'>3</h1>
                        <h1 id='number-four'>4</h1>
                        <h1 id='number-five'>5</h1>
                        
                        
                    </div>
                  

                    <div className='text-container-vantagens'>
                    <h1 className='title-container'>Vantagens de importar conosco</h1>
                        <p className='paragraph-two'>Atuamos como gestora única de toda a operação de importação, planejando e coordenando a parte logística, tributária, operacional e jurídica.
                        </p>
                        <p className='paragraph-two'>
                            Possuímos regimes especiais proporcionando redução da carga tributária e consequentemente, a diminuição no custo final dos produtos importados.
                        </p>
                        <p className='paragraph-two'>
                            Empresa com profissionais a mais de 20 anos no mercado internacional focados e comprometidos a prestar o melhor atendimento, buscando solucionar e simplificar problemas nas Importações.
                        </p>
                        <p className='paragraph-two'>
                        Flexibilizamos o sistema de logística gerenciando todo o processo de importação e/ou exportação assegurando para sua empresa a certeza de otimização de resultados.                        </p>
                        <p className='paragraph-two'>
                        Localização estratégica de nossas unidades abrangendo as regiões Sul, Sudeste e Centro-Oeste.
            
                        </p>
                     

                    </div>
                </div>

                <div className='box-container'>
                    <img id='vantagens-imagem' className='floating' src={VantagensImage} />
                </div>

            </div>

        </>
    )
}