import * as React from 'react';
import '../styles/header-style.css';
import desktopImage from '../img/banner-teste.png'
import mobileImage from '../img/mobile.png'



const Header = () => {
    const final = window.innerWidth >= 650 ? desktopImage : mobileImage;
 
    return (
        /*<div className="App" style={{ backgroundImage: `url(${imageUrl})`, }}> */
        <div className="App" style={{ backgroundImage: `url(${final})`}}>
            <div className="App-content"  >
            </div>
        </div>
    );
};

export default Header;