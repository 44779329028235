import React from "react";

export const NavbarData = [

    {
        title: 'Quem somos',
        path: '/quemsomos',
        li_cName:'nav__item',
        cName: 'nav__link',
        hashlink: '/#quemsomos'
    },
    {
        title: 'Contato',
        path: '/',
        li_cName:'nav__item',
        cName: 'nav__link',
        hashlink: '/#contact'
    }
]

export const RedirectData = [
    {
        title: 'Página Inicial',
        path: '/',
        li_cName:'nav__item',
        cName: 'nav__link',
    },
    {
        title: 'Empresa',
        path: '/empresa',
        li_cName:'nav__item',
        cName: 'nav__link',
    },
]